<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-input v-model="listQuery.order_no" placeholder="请输入订单号" class="filter-item" style="width: 220px;" clearable />

      <el-input v-model="listQuery.catered_student_id" placeholder="请输入包餐学生ID" class="filter-item" style="width: 160px;" clearable />

      <el-select filterable multiple collapse-tags v-model="listQuery.shop_ids" placeholder="请选择店铺" class="filter-item" style="width: 220px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
      </el-select>

      <el-input v-model="listQuery.shop_name" placeholder="请输入店铺名称" class="filter-item" style="width: 220px;" clearable />

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="包餐消费记录.xls" worksheet="包餐消费记录">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading">
      <div style="margin-bottom: 20px;font-weight: bold;">男生：</div>

      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ boy.payMoney || 0 }}</div>
            <div class="subtitle">累计消费金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ boy.payCount || 0 }}</div>
            <div class="subtitle">累计消费次数</div>
          </el-card>
        </el-col>

        <template v-for="item in boy.cateredTimeStatistics">
          <el-col :sm="4">
            <el-card class="box-card">
              <div class="value">{{ item.payMoney || 0 }}</div>
              <div class="subtitle">{{ item.name }}消费金额</div>
            </el-card>
          </el-col>
          <el-col :sm="4">
            <el-card class="box-card">
              <div class="value">{{ item.payCount || 0 }}</div>
              <div class="subtitle">{{ item.name }}消费次数</div>
            </el-card>
          </el-col>
        </template>
      </el-row>

      <div style="margin-bottom: 20px;font-weight: bold;">女生：</div>

      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ girl.payMoney || 0 }}</div>
            <div class="subtitle">累计消费金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ girl.payCount || 0 }}</div>
            <div class="subtitle">累计消费次数</div>
          </el-card>
        </el-col>

        <template v-for="item in girl.cateredTimeStatistics">
          <el-col :sm="4">
            <el-card class="box-card">
              <div class="value">{{ item.payMoney || 0 }}</div>
              <div class="subtitle">{{ item.name }}消费金额</div>
            </el-card>
          </el-col>
          <el-col :sm="4">
            <el-card class="box-card">
              <div class="value">{{ item.payCount || 0 }}</div>
              <div class="subtitle">{{ item.name }}消费次数</div>
            </el-card>
          </el-col>
        </template>
      </el-row>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>

      <el-table-column label="包餐备注名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.catered_student.catered_name }}
        </template>
      </el-table-column>

      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>

      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.phone }}
        </template>
      </el-table-column>

      <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.catered_student.gender === 1" type="primary">男</el-tag>
          <el-tag v-else-if="scope.row.catered_student.gender === 2" type="danger">女</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="店铺ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop_id }}
        </template>
      </el-table-column>

      <el-table-column label="店铺名称" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop.shop_name }}</div>
        </template>
      </el-table-column>

      <el-table-column label="设备编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_id }}
        </template>
      </el-table-column>

      <el-table-column label="本餐价值" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_money }}
        </template>
      </el-table-column>

      <el-table-column label="消费时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="消费时间段" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.system_catered_time.name }}</div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    downloadExcel
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_ids: [],
        shop_name: "",
        catered_student_id: "",
        order_no: "",
        start_date: "",
        end_date: "",
      },
      shops: [],
      dateArr: null,
      json_fields: {
        订单号: {
          field: "order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        包餐备注名: "catered_student.catered_name",
        姓名: "user.name",
        手机号码: {
          field: "user.phone",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        性别: {
          field: "catered_student.gender",
          callback: (value) => {
            if (value === 1) return "男";
            else if (value === 2) return "女";
            else return "";
          },
        },
        店铺ID: {
          field: "shop_id",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        店铺名称: "shop.shop_name",
        设备编号: {
          field: "device_id",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        本餐价值: "pay_money",
        消费时间: {
          field: "created_at",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        消费时间段名称: "system_catered_time.name"
      },
      exportLoading: false,
      boy: {
        payMoney: 0,
        payCount: 0,
        cateredTimeStatistics: [],
      },
      girl: {
        payMoney: 0,
        payCount: 0,
        cateredTimeStatistics: [],
      },
    };
  },
  created() {
    if (this.$route.query.catered_student_id) {
      this.listQuery.catered_student_id = parseInt(this.$route.query.catered_student_id);
    }
    this.getList();
    this.getShopList();
  },
  computed: {
    ...mapGetters(["schools", "school_id", "user"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
    },
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_date = newVal[0];
        this.listQuery.end_date = newVal[1];
      } else {
        this.listQuery.start_date = "";
        this.listQuery.end_date = "";
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/cateredStudent/order",
        method: "get",
        params: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.boy = response.data.boy;
        this.girl = response.data.girl;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleExport() {
      if (!this.listQuery.start_date || !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.page = 1;
      listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/cateredStudent/order",
        method: "get",
        params: listQuery,
        timeout: 6000000,
      }).then(response => {
        if (response.data.list.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.list.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-col{
    margin-bottom: 20px;
  }
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
